import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {NgClass} from '@angular/common';
import {debounceTime, filter} from 'rxjs';
import {ApiGouvAdresseService} from 'src/app/services/api-gouv-adresse.service';
import {ApiGouvAddress, ApiGouvResult} from "../../../../../model/api-gouv-adress.model";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatOptionModule} from "@angular/material/core";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-input-text-google-autocomplete',
  templateUrl: './input-text-google-autocomplete.component.html',
  styleUrls: ['./input-text-google-autocomplete.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, MatProgressSpinnerModule, MatAutocompleteModule, MatOptionModule, MatIconModule]
})
export class InputTextGoogleAutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('addressInput', {static: false}) addressInput: ElementRef<HTMLInputElement>;

  @Input() submitted: boolean; // Si le form a été soumis au moins une fois
  @Input() control: AbstractControl; // FormControl
  @Input() fcName: string; // Nom du FormControl
  @Input() textLabel: string; // Label
  @Input() required = false; // Si le form est requis ou non
  @Input() errorMsg: string; // Message si l'input est required
  @Input() errorMsg2 = ''; // Message si l'input est faux
  @Input() group: UntypedFormGroup; // Group contenant le FormControle
  @Input() i = 0; // i si l'input est dans une boucle (pour éviter d'avoir 2 composants ayant le même id
  @Input() placeholder: string;

  //autocomplete
  city: string = '';
  address: string = '';

  autocomplete: google.maps.places.Autocomplete;
  suggestions: ApiGouvAddress[] = [];
  isLoading: boolean = false;

  constructor(private readonly apiGouvAdresseService: ApiGouvAdresseService) {
  }

  ngAfterViewInit(): void {
    this.group.controls.address1.valueChanges
      .pipe(debounceTime(500), filter(value => value?.length > 3))
      .subscribe(value => {
        if (this.group.controls.address1.dirty) {
          this.isLoading = true;
          this.apiGouvAdresseService.getAdresse(value)
            .then((r: ApiGouvResult) => {
              this.isLoading = false;
              this.suggestions = r.features;
            });
        }
      });
  }

  ngOnInit() {
    if (!this.placeholder) {
      this.placeholder = '';
    }
  }

  isInvalid() {
    if (this.required) {
      return (
        this.control &&
        (this.control.dirty || this.control.touched || this.submitted) &&
        this.control.invalid
      );
    } else {
      return this.control && (this.control.dirty || this.control.touched) && this.control.invalid;
    }
  }

  handleAddressChange(address: ApiGouvAddress) {
    this.group.patchValue({
      city: address.properties.city,
      zipCode: address.properties.postcode,
      address1: address.properties.name
    }, {emitEvent: false});
  }
}
