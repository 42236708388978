export const environment = {
  production: false,
  envApiUrl: 'https://digisport-recette.azurewebsites.net/api',
  instrumentationKey: '861d271b-03b1-49e7-919c-0e84f0d85793',
  timestamp: '11/02/2025',
  appVersion: require('../../package.json').version,
  serviceWorker: {
    enabled: true
  },
  googleMaps: {
    apiKey: 'AIzaSyCLle5HbTD2lV6Lue5cixoMmYNs26TqmMs'
  },
  azure: {
    activeDirectory: {
      clientId: '7892195f-339c-479e-89e6-375d17bfc443',
      authority: 'https://login.microsoftonline.com/241ad2a7-0bee-45fb-b4ad-0fc4b38e7e9b',
      scope: 'api://7892195f-339c-479e-89e6-375d17bfc443/Utilisateurs',
      redirectUri: '/dashboard',
      postLogoutRedirectUri: 'https://rec.digisportdanslaville.com',
      enableLog: false
    }
  }
};

export const globalAppParam = {
  minDatePicker: new Date(2001, 12, 31),
  limitDateInscription: new Date(new Date().getFullYear(), 7, 1),
  enableEventCreationNextYear: new Date(new Date().getFullYear(), 5, 1),
  envName: 'recette'
};
