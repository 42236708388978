@if (group) {

  <div [formGroup]="group" class="form-group" style="position: relative">
    @if(textLabel) {
      <label [for]="fcName+i" [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>
    }

    <input #addressInput [formControlName]="fcName" [id]="fcName + i"
           [name]="fcName + i"
           [ngClass]="{'is-invalid': isInvalid() }"
           [placeholder]="placeholder"
           [matAutocomplete]="place"
           class="form-control"
           type="search"
           autocomplete="off">

    @if (isLoading) {
      <mat-spinner [diameter]="25" class="custom-theme spinner"></mat-spinner>
    }

    <mat-autocomplete #place="matAutocomplete">
      @for (suggestion of suggestions; track suggestion.properties.label) {
        <mat-option (click)="handleAddressChange(suggestion)">
          <mat-icon>location_on</mat-icon>
          {{suggestion.properties.name}},
          <span class="city">
            {{suggestion.properties.city}}
            ({{suggestion.properties.context}})
          </span>
        </mat-option>
      }
    </mat-autocomplete>

    @if(control && control.invalid && (errorMsg || errorMsg2 ) && submitted) {
      <div class="invalid-feedback">
        @if(control.errors.required) {
          <div>{{errorMsg}}</div>
        } @else {
          <div>{{errorMsg2}}</div>
        }
      </div>
    }
  </div>

} @else {
  <div class="form-group">
    @if(textLabel) {
      <label [for]="fcName+i" [ngClass]="{'star': required}" class="label-info">{{textLabel}} </label>
    }
    <input #addressInput [id]="fcName + i" [name]="fcName + i"
           [placeholder]="placeholder"
           class="form-control"
           type="search"
           autocomplete="off">
    @if (isLoading) {
      <mat-spinner [diameter]="25" class="custom-theme spinner"></mat-spinner>
    }
    <mat-autocomplete #place="matAutocomplete">
      @for (suggestion of suggestions; track suggestion.properties.label) {
        <mat-option (click)="handleAddressChange(suggestion)">{{suggestion.properties.label}}</mat-option>
      }
    </mat-autocomplete>
  </div>

}




