import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'platform'
})
export class ApiGouvAdresseService {

  constructor() {
  }

  getAdresse(text: string): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${text}&limit=10`)
        .then(response => response.json())
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
